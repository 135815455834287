import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IoClose, IoTimerOutline, IoSend } from "react-icons/io5";

import { ComposeContext } from "../context/compose_tweet_context";
import { AuthContext } from "../context/auth_context";

import "react-toastify/dist/ReactToastify.css";
import { BadgeCheckIcon } from "../icons";
import {
  Avatar,
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  ScrollShadow,
  Spacer,
  Button,
  Tooltip,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import ReactGiphySearchbox from "react-giphy-searchbox";

import MediaUploadModal from "./media_upload_modal";

function TweetPreview({ close }) {
  const { account } = useContext(AuthContext);

  const [tmpTweet, setTmpTweet] = useState([]);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [threadUploadIndex, setThreadUploadIndex] = useState(null);
  const [isGifSelect, setIsGifSelect] = useState({});

  const randomNumbersLikeRef = useRef({}); // Ref to store random numbers
  const randomNumbersRetweetRef = useRef({}); // Ref to store retweet random numbers
  const randomNumbersCommentRef = useRef({}); // Ref to store retweet random numbers
  const randomNumbersImpressionRef = useRef({}); // Ref to store retweet random numbers
  const fileInputRef = useRef(null);

  const { tweet, mediaPreview, threadMedia, setThreadMedia, selectedMedia } =
    useContext(ComposeContext);

  useEffect(() => {
    const threads = tweet.split(/\n\s*\n\s*\n/);
    console.log("threads", threads);
    setTmpTweet(threads);

    threads.forEach((_, index) => {
      if (!(index in randomNumbersLikeRef.current)) {
        randomNumbersLikeRef.current[index] =
          Math.floor(Math.random() * 1000) + 1;
      }
      if (!(index in randomNumbersRetweetRef.current)) {
        randomNumbersRetweetRef.current[index] =
          Math.floor(Math.random() * 100) + 1;
      }
      if (!(index in randomNumbersCommentRef.current)) {
        randomNumbersCommentRef.current[index] =
          Math.floor(Math.random() * 250) + 1;
      }
      if (!(index in randomNumbersImpressionRef.current)) {
        randomNumbersImpressionRef.current[index] =
          Math.floor(Math.random() * 1500) + 1;
      }
    });
  }, [tweet]);

  const handleFileUpload = (event) => {
    console.log("file changed");
    setIsUploadModal(false);
    const files = Array.from(event.target.files);
    console.log("files", files, "thread index", threadUploadIndex);

    setThreadMedia((prevThreadMedia) => {
      const updatedMedia = [...prevThreadMedia];
      updatedMedia[threadUploadIndex] = [
        ...(updatedMedia[threadUploadIndex] || []),
        ...files,
      ];
      return updatedMedia;
    });

    // Clear input after upload
    event.target.value = null;
  };

  console.log("thread media>>>>", threadMedia);

  const deleteMedia = (threadIndex, mediaIndex) => {
    setThreadMedia((prevThreadMedia) => {
      const updatedMedia = [...prevThreadMedia]; // Copy the existing thread media
      const threadMediaArray = updatedMedia[threadIndex]; // Get the media array for the specific thread

      if (threadMediaArray && threadMediaArray.length > 0) {
        threadMediaArray.splice(mediaIndex, 1); // Remove the media at mediaIndex
        updatedMedia[threadIndex] =
          threadMediaArray.length > 0 ? threadMediaArray : null; // Update the thread's media or set it to null if empty
      }

      return updatedMedia;
    });
  };

  const handleSelectGif = (gif) => {
    console.log("thread index", threadUploadIndex);
    const gifUrl = gif.images.original.url; // Get the GIF URL

    setThreadMedia((prevThreadMedia) => {
      const updatedMedia = [...prevThreadMedia];
      updatedMedia[threadUploadIndex] = [
        ...(updatedMedia[threadUploadIndex] || []),
        gifUrl,
      ];
      return updatedMedia;
    });
    setIsUploadModal(false);

    // setIsGifSelect((prev) => ({ ...prev, [threadUploadIndex]: false })); // Close GIF selector after selecting
  };

  console.log("threadMedia", threadMedia);
  return (
    <>
      <MediaUploadModal
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        handleFileUpload={handleFileUpload}
        fileInputRef={fileInputRef}
        isGifDisabled={false}
        handleSelectGif={handleSelectGif}
      />
      <Card
        className="border-none max-w-[610px] h-screen bg-gradient-to-r from-violet-300/50 to-purple-100/50 dark:from-violet-300/20 dark:to-purple-100/20"
        isBlurred
        shadow="sm"
      >
        <CardHeader className="flex gap-3">
          <Icon icon="hugeicons:bubble-chat-preview" width={23} />

          <div className="flex items-center justify-between w-full">
            <p className="text-sm">Preview</p>
            <Icon
              icon="solar:close-square-outline"
              width={20}
              className="cursor-pointer"
              onClick={() => close(false)}
            />
          </div>
        </CardHeader>
        <Divider />
        <CardBody>
          <ScrollShadow className="flex flex-col gap-2 h-[95%]">
            {tmpTweet.map((tweet, index) => (
              <div className="flex gap-3" key={index}>
                <div className="relative flex-none">
                  <Avatar
                    src={account?.data?.profile_pic}
                    classNames={{ base: "w-8 h-8" }}
                  />
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="relative w-full rounded-medium bg-content2 px-4 py-2 text-default-600">
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <div className="flex gap-1 items-center w-full text-tiny font-semibold text-default-foreground">
                          {account?.data?.name}{" "}
                          {account?.data?.is_blue_tick && (
                            <Icon
                              icon="mage:verified-check-fill"
                              className="text-blue-500"
                              width={18}
                            />
                          )}
                        </div>
                        <div className="flex-end text-tiny text-default-400">
                          @{account?.data?.username}
                        </div>
                      </div>
                      {index !== 0 && (
                        <div className="flex">
                          <div className="flex gap-1 items-center">
                            <Tooltip content="Upload image, video, or GIF">
                              <Button
                                isIconOnly
                                onClick={() => {
                                  setThreadUploadIndex(index);
                                  setIsUploadModal(true);
                                }}
                                size="sm"
                                color="primary"
                                variant="faded"
                              >
                                <Icon icon="mdi:tray-upload" width={16} />
                              </Button>
                            </Tooltip>
                            {/* <label className="cursor-pointer rounded-md p-1 text-gray-600 hover:text-gray-800 hover:bg-gray-200">
                              <Icon
                                icon="mdi:tray-upload"
                                width={16}
                                onClick={() => {
                                  setThreadUploadIndex(index);
                                  setIsUploadModal(true);
                                }}
                              />
                            </label> */}
                          </div>
                          {/* <div className="flex justify-between items-center gap-2">
                            <div className="flex gap-1 items-center">
                              <Icon
                                icon="mdi:gif-box"
                                width={20}
                                className="cursor-pointer"
                                onClick={() =>
                                  setIsGifSelect((prev) => ({
                                    ...prev,
                                    [index]: !prev[index],
                                  }))
                                }
                              />
                            </div>
                            <div
                              className={`p-1 rounded-md hover:bg-green-200 cursor-pointer ${
                                isGifSelect
                                  ? "bg-green-200"
                                  : false
                                  ? "bg-green-100 dark:bg-green-50"
                                  : ""
                              }`}
                              onClick={() =>
                                setIsGifSelect((prev) => ({
                                  ...prev,
                                  [index]: !prev[index],
                                }))
                              }
                            >
                              <Icon
                                icon="teenyicons:gif-outline"
                                width={17}
                                className={`${
                                  false
                                    ? "text-green-400"
                                    : "text-green-700 dark:text-green-600"
                                }`}
                              />
                            </div>
                          </div> */}
                        </div>
                      )}
                    </div>
                    <div className="mt-2 text-tiny text-default-600 whitespace-pre-line">
                      {tweet}
                    </div>

                    <Spacer y={3} />
                    <div className="flex justify-between items-center gap-2">
                      <div className="flex gap-1 items-center">
                        <Icon icon="gravity-ui:comment" width={15} />
                        <p className=" text-default-400 text-tiny">
                          {randomNumbersCommentRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="flat-color-icons:like" width={15} />
                        <p className=" text-default-400 text-tiny">
                          {randomNumbersLikeRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="ant-design:retweet-outlined" width={15} />
                        <p className="text-default-400 text-tiny">
                          {randomNumbersRetweetRef.current[index]}
                        </p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <Icon icon="bx:bar-chart" width={15} />
                        <p className="text-default-400 text-tiny">
                          {randomNumbersImpressionRef.current[index]}
                        </p>
                      </div>
                      <div className="flex items-center text-xs font-semibold gap-1">
                        {/* <Icon icon="mdi:text-box" width={20} /> */}
                        <p className="text-default-400 text-tiny">
                          {tweet.length}
                        </p>
                      </div>

                      {/* <p
                        className={`text-end text-xs font-semibold ${
                          tweet.length > 280 && "text-red-500"
                        }`}
                      >
                        {tweet.length}
                      </p> */}
                    </div>
                    {isGifSelect[index] && (
                      <ReactGiphySearchbox
                        apiKey="zpgHg1e3m6vBwiwgYAEBdUJnhWjuC7eA"
                        onSelect={(gif) => handleSelectGif(gif, index)}
                        columns={3}
                      />
                    )}
                  </div>
                  {/* Display main media under the first tweet */}
                  {index === 0 && selectedMedia.length > 0 && (
                    <div className="mt-2 flex flex-wrap gap-2">
                      {selectedMedia.map((media, mediaIndex) => (
                        <div key={mediaIndex}>
                          {typeof media === "string" ? (
                            <Image
                              alt={`GIF ${mediaIndex}`}
                              src={media} // Use the GIF URL
                              radius="sm"
                            />
                          ) : media?.type?.startsWith("video") ? (
                            <video
                              controls
                              className="w-full h-auto rounded-sm"
                            >
                              <source src={URL.createObjectURL(media)} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <Image
                              alt={`Thread Media ${mediaIndex}`}
                              src={URL.createObjectURL(media)}
                              radius="sm"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {/* Display thread-specific media */}
                  {index !== 0 &&
                    threadMedia[index] &&
                    threadMedia[index].length > 0 && (
                      <div className="grid grid-cols-1 gap-2 my-2">
                        {threadMedia[index].map((media, mediaIndex) => (
                          <div key={mediaIndex} className="flex flex-col gap-2">
                            {typeof media === "string" ? (
                              <Image
                                alt={`GIF ${mediaIndex}`}
                                src={media} // Use the GIF URL
                                radius="sm"
                              />
                            ) : media?.type?.startsWith("video") ? (
                              <video
                                controls
                                className="w-full h-auto rounded-sm"
                              >
                                <source src={URL.createObjectURL(media)} />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <Image
                                alt={`Thread Media ${mediaIndex}`}
                                src={URL.createObjectURL(media)}
                                radius="sm"
                              />
                            )}

                            <Icon
                              icon="carbon:close-outline"
                              size={16}
                              className="mx-auto cursor-pointer"
                              onClick={() => deleteMedia(index, mediaIndex)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
            ))}
            {/* {mediaPreview.map((preview, index) => (
              <>
                <div className="flex gap-3" key={index}>
                  <div className="relative flex-none">
                    <Avatar
                      src={account?.data?.profile_pic}
                      classNames={{ base: "w-8 h-8" }}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-4">
                    <div className="relative w-full rounded-medium bg-content2 p-2 text-default-600">
                      <div key={index} className="flex flex-col gap-2">
                        {preview.type === "video" ? (
                          <video controls className="w-full h-auto rounded-sm">
                            <source src={preview.url} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <Image
                            alt={`Preview ${index}`}
                            src={preview.url}
                            radius="sm"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))} */}
          </ScrollShadow>
        </CardBody>
        <Divider />
      </Card>
    </>
  );
}

export default TweetPreview;
